import React from 'react';

const ArrowIcon = () => {
  return (
    <svg width="210" height="59" viewBox="0 0 210 59" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <path
            d="M29.3 58h10.32V46.4h7.68v-7.92h-7.68V.8H26.26L.98 42.64v3.76H29.3V58zM12.34 38.48l17.12-29.6v29.6H12.34z"
            fill="#111"
          />
          <path
            d="M60.5 58.56c-3.52 0-6.32-2.72-6.32-6.24S56.98 46 60.5 46c3.6 0 6.4 2.8 6.4 6.32 0 3.52-2.8 6.24-6.4 6.24z"
            fill="#00D777"
          />
          <path
            d="M96.42 50.32c-8.56 0-13.52-6.56-13.52-19.52v-2.88c0-12.72 4.72-19.6 13.36-19.6 8.56 0 13.2 7.36 13.2 19.52v2.8c0 12.96-4.24 19.68-13.04 19.68zm-.24 8.48c-14.56 0-24-10.72-24-27.92V28c0-17.36 9.6-28 24-28 14.48 0 23.92 10.56 23.92 27.76v2.88c0 17.2-8.96 28.16-23.92 28.16z"
            fill="#111"
          />
          <path
            d="M131.7 58.56c-3.52 0-6.32-2.72-6.32-6.24s2.8-6.32 6.32-6.32c3.6 0 6.4 2.8 6.4 6.32 0 3.52-2.8 6.24-6.4 6.24z"
            fill="#00D777"
          />
          <path
            d="M171.7 58h10.32V46.4h7.68v-7.92h-7.68V.8h-13.36l-25.28 41.84v3.76h28.32V58zm-16.96-19.52l17.12-29.6v29.6h-17.12z"
            fill="#111"
          />
          <path
            d="M202.9 58.56c-3.52 0-6.32-2.72-6.32-6.24s2.8-6.32 6.32-6.32c3.6 0 6.4 2.8 6.4 6.32 0 3.52-2.8 6.24-6.4 6.24z"
            fill="#00D777"
          />
        </g>
        <path
          d="M96.42 50.32c-8.56 0-13.52-6.56-13.52-19.52v-2.88c0-12.72 4.72-19.6 13.36-19.6 8.56 0 13.2 7.36 13.2 19.52v2.8c0 12.96-4.24 19.68-13.04 19.68z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};

export default React.memo(ArrowIcon);
